import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getDatabase, ref as dbRef, onValue } from "firebase/database";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const IngredientList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
`;

const IngredientItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? "#f0f0f0" : "white")};

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: var(--color-darkorange);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background-color: var(--color-orange);
  }
`;

const QuantityInput = styled.input`
  width: 60px;
  padding: 5px;
  margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SelecionarIngredientesModal = ({ onClose, onSave, selectedIngredients, newIngredients, setNewIngredients }) => {
  const [ingredients, setIngredients] = useState([]);
  const [selected, setSelected] = useState(selectedIngredients);
  const [quantities, setQuantities] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [step, setStep] = useState(1);

  useEffect(() => {
    const db = getDatabase();
    const ingredientsRef = dbRef(db, "ingredients");

    onValue(ingredientsRef, (snapshot) => {
      const data = snapshot.val();
      const ingredientsList = Object.keys(data).map((key) => ({
        id: key,
        ...data[key],
      }));
      setIngredients(ingredientsList);
    });
  }, []);

  const handleIngredientSelect = (ingredient) => {
    setNewIngredients((prev) =>
      prev.includes(ingredient)
        ? prev.filter((r) => r !== ingredient)
        : [...prev, ingredient]
    );
  };

  const handleQuantityChange = (ingredientId, quantity) => {
    setQuantities((prev) => ({
      ...prev,
      [ingredientId]: quantity,
    }));
  };

  const filteredIngredients = ingredients.filter((ingredient) =>
    ingredient.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleNextStep = () => {
    if (newIngredients.length > 0) {
      setStep(2);
    } else {
      alert("Selecione pelo menos um ingrediente.");
    }
  };

  const handleSave = () => {
    const selectedWithQuantities = newIngredients.map((ingredient) => ({
      ...ingredient,
      quantity: quantities[ingredient.id] || 0,
    }));
    onSave(selectedWithQuantities);
  };

  return (
    <ModalOverlay>
      <ModalContent>
        {step === 1 ? (
          <>
            <Title>Selecione os ingredientes</Title>
            <SearchBar
              type="text"
              placeholder="Buscar ingrediente..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <IngredientList>
              {filteredIngredients.map((ingredient) => (
                <IngredientItem
                  key={ingredient.id}
                  onClick={() => handleIngredientSelect(ingredient)}
                  selected={newIngredients.includes(ingredient)}
                >
                  <span>{ingredient.name}</span>
                  <input
                    type="checkbox"
                    checked={newIngredients.includes(ingredient)}
                    readOnly
                  />
                </IngredientItem>
              ))}
            </IngredientList>
            <Button onClick={handleNextStep}>Próximo</Button>
            <Button onClick={onClose}>Fechar</Button>
          </>
        ) : (
          <>
            <Title>Informe a quantidade</Title>
            <IngredientList>
              {newIngredients.map((ingredient) => (
                <IngredientItem key={ingredient.id}>
                  <span>{ingredient.name}</span>
                  <QuantityInput
                    type="number"
                    placeholder="g/ml"
                    value={quantities[ingredient.id] || ""}
                    onChange={(e) =>
                      handleQuantityChange(ingredient.id, e.target.value)
                    }
                  />
                </IngredientItem>
              ))}
            </IngredientList>
            <Button onClick={handleSave}>Salvar</Button>
            <Button onClick={() => setStep(1)}>Voltar</Button>
            <Button onClick={onClose}>Fechar</Button>
          </>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

export default SelecionarIngredientesModal;