import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { getDatabase, ref as dbRef, onValue, update } from "firebase/database";
import { FaPencilAlt, FaSave, FaTimes } from "react-icons/fa";
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";

const ReceitaRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-200);
  font-family: var(--font-roboto);
  overflow-x: hidden;
  overflow-y: auto;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  text-align: left;
`;

const RecipeTitle = styled.h1`
  font-size: 1.5rem;
  color: var(--color-white);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const EditIcon = styled(FaPencilAlt)`
  margin-left: 10px;
  cursor: pointer;
`;

const SaveIcon = styled(FaSave)`
  margin-left: 10px;
  cursor: pointer;
  color: var(--color-white);
`;

const CancelIcon = styled(FaTimes)`
  margin-left: 10px;
  cursor: pointer;
  color: var(--color-white);
`;

const EditInput = styled.input`
  font-size: 1.5rem;
  font-family: var(--font-roboto);
  color: var(--color-white);
  background: none;
  border: none;
  border-bottom: 1px solid var(--color-white);
  margin-bottom: 10px;
  width: auto;
`;

const RecipeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  width: 100%;
`;

const RecipeCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const RecipeImage = styled.img`
  width: 100%;
  max-width: 220px;
  height: 156px;
  border-radius: var(--br-5xs);
  object-fit: cover;
  border: 1px solid orange;
`;

const RecipeName = styled.div`
  margin-top: 10px;
  font-size: 1rem;
  color: var(--color-white);
`;

const Receita = () => {
  const { recipeId } = useParams(); // Fetch the recipeId from URL params
  const [recipe, setRecipe] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newDescription, setNewDescription] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (recipeId) {
      const db = getDatabase();
      const recipeRef = dbRef(db, `recipes/${recipeId}`);

      onValue(recipeRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setRecipe(data);
          setNewDescription(data.description);
        } else {
          console.error("Recipe not found");
        }
      });
    }
  }, [recipeId]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    if (window.confirm("Tem certeza que deseja editar o nome da receita?")) {
      const db = getDatabase();
      const recipeRef = dbRef(db, `recipes/${recipeId}`);
      update(recipeRef, { description: newDescription })
        .then(() => {
          setRecipe((prev) => ({ ...prev, description: newDescription }));
          setIsEditing(false);
        })
        .catch((error) => {
          console.error("Error updating recipe description:", error);
        });
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setNewDescription(recipe.description);
  };

  return (
    <ReceitaRoot>
      <FunctionsHeader onLogoClick={() => navigate("/home")} />
      <Breadcrumb path={`Página inicial > Receitas > ${recipe?.description || "Carregando..."}`} />
      <MainContent>
        <ContentWrapper>
          <Header>
            {isEditing ? (
              <div>
                <EditInput
                  type="text"
                  value={newDescription}
                  onChange={(e) => setNewDescription(e.target.value)}
                />
                <SaveIcon onClick={handleSaveClick} />
                <CancelIcon onClick={handleCancelClick} />
              </div>
            ) : (
              <RecipeTitle>
                {recipe?.description || "Frango a passarinho acebolado"}
                <EditIcon onClick={handleEditClick} />
              </RecipeTitle>
            )}
          </Header>
          <RecipeGrid>
            <RecipeCard onClick={() => navigate(`/cliente/${recipeId}`)}>
              <RecipeImage src={recipe?.image || "/default-recipe-image.webp"} alt={recipe?.description} />
              <RecipeName>Contém na dieta dos clientes</RecipeName>
            </RecipeCard>
            <RecipeCard onClick={() => navigate(`/add-variant/${recipeId}`)}>
              <RecipeImage src={recipe?.image || "/default-recipe-image.webp"} alt={recipe?.description} />
              <RecipeName>Adicionar uma variante</RecipeName>
            </RecipeCard>
            <RecipeCard onClick={() => navigate(`/receita/${recipeId}/ingredientes`)}>
              <RecipeImage src={recipe?.image || "/default-recipe-image.webp"} alt={recipe?.description} />
              <RecipeName>Ingredientes</RecipeName>
            </RecipeCard>
            <RecipeCard onClick={() => navigate(`/view-variants/${recipeId}`)}>
              <RecipeImage src={recipe?.image || "/default-recipe-image.webp"} alt={recipe?.description} />
              <RecipeName>Ver variantes</RecipeName>
            </RecipeCard>
          </RecipeGrid>
        </ContentWrapper>
      </MainContent>
    </ReceitaRoot>
  );
};

export default Receita;
