import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { getDatabase, ref as dbRef, onValue } from "firebase/database";
import { getStorage, ref as storageRef, getDownloadURL, uploadBytes } from "firebase/storage";
import FunctionsHeader from "../components/FunctionsHeader";
import Breadcrumb from "../components/Breadcrumb";

const PerfilIngredienteRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-200);
  font-family: var(--font-roboto);
  overflow-x: hidden;
  overflow-y: auto;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const HeroSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const IngredientInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
`;

const IngredientTitle = styled.h2`
  font-size: 1.5rem;
  color: var(--color-white);
  text-align: center;
  margin-bottom: 10px;
`;

const IngredientImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  position: relative;

  &:hover::after {
    content: "Alterar imagem";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

const IngredientImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 20px;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  border: 2px solid var(--color-darkorange);
  font-size: 0.9rem;
  cursor: pointer;
  text-align: center;
  min-width: 180px;

  &:hover {
    background-color: var(--color-darkorange);
    color: var(--color-white);
  }
`;

const RecipesWrapper = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: var(--br-5xs);
  color: var(--color-white);
  background-color: var(--color-black);
  max-height: fit-content;
`;

const RecipesTitle = styled.h2`
  font-size: 1.5rem;
  color: var(--color-white);
  text-align: center;
  margin-bottom: 20px;
`;

const RecipesGrid = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  overflow: visible;
`;

const RecipeCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  max-width: 157px;

  &:hover {
    opacity: 0.8;
  }
`;

const RecipeImage = styled.img`
  width: 157px;
  height: 157px;
  border: 1px solid orange;
  border-radius: var(--br-5xs);
  object-fit: cover;
  margin-bottom: 10px;
`;

const AddRecipeCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

const AddRecipeButton = styled.button`
  width: 157px;
  height: 157px;
  border-radius: 50%;
  border: none;
  background-color: var(--color-darkorange);
  color: var(--color-white);
  font-size: 2rem;
  cursor: pointer;

  &:hover {
    background-color: var(--color-orange);
  }
`;

const PerfilIngrediente = () => {
  const { id } = useParams(); // Get the id from the URL
  const navigate = useNavigate();
  const [ingredientData, setIngredientData] = useState(null);
  const [ingredientPicUrl, setIngredientPicUrl] = useState('');
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    if (id) {
      const db = getDatabase();
      const ingredientRef = dbRef(db, `ingredients/${id}`);

      onValue(ingredientRef, (snapshot) => {
        const data = snapshot.val();
        setIngredientData(data);

        // Fetch ingredient picture from Firebase Storage
        if (data.image) {
          const storage = getStorage();
          const picRef = storageRef(storage, data.image);
          getDownloadURL(picRef)
            .then((url) => setIngredientPicUrl(url))
            .catch((error) => console.error("Error fetching ingredient pic:", error));
        }

        if (data.recipes) {
          // Fetch each recipe based on the recipe IDs
          const recipePromises = Object.keys(data.recipes).map(recipeId =>
            new Promise((resolve) => {
              const recipeRef = dbRef(db, `recipes/${recipeId}`);
              onValue(recipeRef, (recipeSnapshot) => {
                resolve({ id: recipeId, ...recipeSnapshot.val() });
              });
            })
          );

          // Resolve all promises and set recipes state
          Promise.all(recipePromises).then(setRecipes);
        }
      });
    }
  }, [id]);

  const handleRecipeClick = (recipeId) => {
    navigate(`/receita/${recipeId}`);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const storage = getStorage();
      const picRef = storageRef(storage, `ingredients/picture/${id}`);
      uploadBytes(picRef, file).then(() => {
        getDownloadURL(picRef)
          .then((url) => setIngredientPicUrl(url))
          .catch((error) => console.error("Error fetching new ingredient pic:", error));
      }).catch((error) => console.error("Error uploading new ingredient pic:", error));
    }
  };

  const triggerFileInput = () => {
    document.getElementById('fileInput').click();
  };

  if (!ingredientData) {
    return <div>Loading...</div>; // Add a loading state
  }

  return (
    <PerfilIngredienteRoot>
      <FunctionsHeader onLogoClick={() => navigate("/home")} />
      <Breadcrumb path={`Página inicial > Ingredientes > ${ingredientData.name || 'Ingrediente'}`} />
      <MainContent>
        <ContentWrapper>
          <HeroSection>
            <IngredientInfo>
              <IngredientTitle>{ingredientData.name}</IngredientTitle>
              <IngredientImageWrapper onClick={triggerFileInput}>
                <IngredientImage
                  src={ingredientPicUrl || "/default-ingredients.jpg"}
                  alt={ingredientData.name}
                />
                <HiddenFileInput
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </IngredientImageWrapper>
            </IngredientInfo>
            <ActionButtons>
              <ActionButton onClick={() => navigate(`/precificao-${id}`)}>Precificação</ActionButton>
              <ActionButton onClick={() => navigate(`/resumo-${id}`)}>Resumo sobre o ingrediente</ActionButton>
              <ActionButton onClick={() => navigate(`/informacao-nutricional-${id}`)}>Informação nutricional</ActionButton>
            </ActionButtons>
          </HeroSection>
          <RecipesTitle>Receitas que contêm o ingrediente</RecipesTitle>
          <RecipesWrapper>
            <RecipesGrid>
              {recipes.map((recipe) => (
                <RecipeCard key={recipe.id} onClick={() => handleRecipeClick(recipe.id)}>
                  <RecipeImage src={recipe.image || "/default-ingredients.jpg"} alt={recipe.description} />
                  <span>{recipe.description}</span>
                </RecipeCard>
              ))}
              <AddRecipeCard onClick={() => navigate("/adicionar-prato")}>
                <AddRecipeButton>+</AddRecipeButton>
                <span>Adicionar a uma receita</span>
              </AddRecipeCard>
            </RecipesGrid>
          </RecipesWrapper>
        </ContentWrapper>
      </MainContent>
    </PerfilIngredienteRoot>
  );
};

export default PerfilIngrediente;