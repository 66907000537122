import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Receita from "./pages/Receita";
import ResumoCebola from "./pages/ResumoCebola";
import GerenciarPerfis from "./pages/GerenciarPerfis";
import Home from "./pages/Home";
import BusqueOuPesquisePorUmIngrediente from "./pages/BusqueOuPesquisePorUmIngrediente";
import BusqueOuPesquisePorUmaReceita from "./pages/BusqueOuPesquisePorUmaReceita";
import Ingredientes from "./pages/Ingredientes";
import PerfilDoCliente from "./pages/PerfilDoCliente";
import PerfilIngrediente from "./pages/PerfilIngrediente";
import SelecioneUmCliente from "./pages/SelecioneUmCliente";
import Login from "./pages/Login";
import EditarPerfil from "./pages/EditarPerfil";
import Clientes from "./pages/Clientes";
import PrecificaoBatata from "./pages/PrecificaoBatata";
import BusqueOuPesquisePorUmIngredienteFiltro from "./pages/BusqueOuPesquisePorUmIngredienteFiltro";
import AdicionarPerfil from "./pages/AdicionarPerfil";
import AdicionarIngrediente from "./pages/AdicionarIngrediente";
import AdicionarReceita from "./pages/AdicionarReceita";
import ResumoBatata from "./pages/ResumoBatata";
import Receitas from "./pages/Receitas";
import Impressao from "./pages/Impressao";
import IngredientesDaReceita from "./pages/IngredientesDaReceita";
import ClientFiles from "./pages/ClientFiles";
import PrintInstructions from "./pages/PrintInstructions";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/resumo-cebola":
        title = "";
        metaDescription = "";
        break;
      case "/gerenciar-perfis":
        title = "";
        metaDescription = "";
        break;
      case "/home":
        title = "";
        metaDescription = "";
        break;
      case "/busque-ou-pesquise-por-um-ingrediente":
        title = "";
        metaDescription = "";
        break;
      case "/ingredientes":
        title = "";
        metaDescription = "";
        break;
      case "/perfil-do-rogerio":
        title = "";
        metaDescription = "";
        break;
      case "/perfil-batata":
        title = "";
        metaDescription = "";
        break;
      case "/selecione-um-cliente":
        title = "";
        metaDescription = "";
        break;
      case "/login":
        title = "";
        metaDescription = "";
        break;
      case "/editar-perfil":
        title = "";
        metaDescription = "";
        break;
      case "/clientes":
        title = "";
        metaDescription = "";
        break;
      case "/precificao-batata":
        title = "";
        metaDescription = "";
        break;
      case "/busque-ou-pesquise-por-um-ingrediente-filtro":
        title = "";
        metaDescription = "";
        break;
      case "/adicionar-perfil":
        title = "";
        metaDescription = "";
        break;
      case "/resumo-batata":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]',
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/resumo-cebola" element={<ResumoCebola />} />
      <Route path="/gerenciar-perfis" element={<GerenciarPerfis />} />
      <Route path="/home" element={<Home />} />
      <Route
        path="/busque-ou-pesquise-por-um-ingrediente"
        element={<BusqueOuPesquisePorUmIngrediente />}
      />
      <Route path="/ingredientes" element={<Ingredientes />} />
      <Route path="/criar-ingrediente" element={<AdicionarIngrediente />} />
      <Route
        path="/busque-ou-pesquise-por-uma-receita"
        element={<BusqueOuPesquisePorUmaReceita />}
      />
      <Route path="/receitas" element={<Receitas />} />
      <Route path="/criar-receita" element={<AdicionarReceita />} />
      <Route path="/cliente/:id" element={<PerfilDoCliente />} />
      <Route path="/cliente/:id/arquivos" element={<ClientFiles />} />
      <Route path="/ingrediente/:id" element={<PerfilIngrediente />} />
      <Route path="/selecione-um-cliente" element={<SelecioneUmCliente />} />
      <Route path="/receita/:recipeId" element={<Receita />} />
      <Route path="/editar-perfil/:userId" element={<EditarPerfil />} />
      <Route path="/clientes" element={<Clientes />} />
      <Route path="/precificao-batata" element={<PrecificaoBatata />} />
      <Route
        path="/busque-ou-pesquise-por-um-ingrediente-filtro"
        element={<BusqueOuPesquisePorUmIngredienteFiltro />}
      />
      <Route path="/adicionar-cliente" element={<AdicionarPerfil />} />
      <Route path="/resumo-batata" element={<ResumoBatata />} />
      <Route path="/imprimir" element={<Impressao />} />
      <Route path="/imprimir/instrucoes-cozinha" element={<PrintInstructions />} />
      <Route path="/receita/:recipeId/ingredientes" element={<IngredientesDaReceita />} />
    </Routes>
  );
}
export default App;
